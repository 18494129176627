export default {
  verification: {},
  verifications: [],
  verificationForm: {},
  contactTable: {},
  addressTable: {},
  emailAgeResponse: {},
  teleSignResponse: {},
  idScanResponse: {},
  uspsResponse: {},
  transUnionTLOxp: {},
}
