// import crudActions from '@/libs/storeConstActions'
import store from '@/store'
import axios from '@/libs/axios'

// const endpoint = () => `customers/${store.state.customers?.customer?.id}/verification`
const validateVerification = (ctx, { id, data }) => axios.post(`/customers/${id}/validate`, data)
// const getVerification = () => axios.get(`/customers/${store.state.customers?.customer?.id}/verification`)
const getDefaults = () => axios.get('/settings/customers/default')
const deleteAttachment = (ctx, data) => axios.delete(`/attachments/${data.id}`)

const queryServiceCheck = (ctx, data) => axios.post(`/customers/${data.id}/validate-query-services`)

const requestDocument = (ctx, data) => axios.post(`/customers/${data.customer}/request-documents`, data)

// const Actions = crudActions(endpoint)

const getVerification = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  axios.get(`/customers/${store.state.customers?.customer?.id}/verification`, { params: queryParams })
    .then(response => {
      commit('GET', response.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export default {
  // ...Actions,
  getDefaults,
  getVerification,
  deleteAttachment,
  validateVerification,
  requestDocument,
  queryServiceCheck,
}
