export default {
  GET(state, data) {
    state.verification = { ...data }
  },
  LIST(state, data) {
    state.verifications = data
  },
  SET_FORM(state, data) {
    state.verificationForm = { ...data }
  },
  SET_CONTACT_TABLE(state, data) {
    state.contactTable = { ...data }
  },
  SET_ADDRESS_TABLE(state, data) {
    state.addressTable = { ...data }
  },
  SET_EMAIL_AGE_RESPONSE(state, data) {
    state.emailAgeResponse = { ...data }
  },
  SET_TELE_SIGN_RESPONSE(state, data) {
    state.teleSignResponse = { ...data }
  },
  SET_ID_SCAN_RESPONSE(state, data) {
    state.idScanResponse = { ...data }
  },
  SET_USPS_RESPONSE(state, data) {
    state.uspsResponse = { ...data }
  },
  SET_TRANS_UNION_TLOXP_RESPONSE(state, data) {
    state.transUnionTLOxp = { ...data }
  },
}
