<template>
  <router-view />
</template>
<script>

import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import verificationModule from '@/store/main/customer/verification'

export default {
  name: 'Verification',
  created() {
    this.$emit('updateMenu', 'home-customers-verification')
  },
  setup() {
    const MODULE_NAME = 'verification'

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, verificationModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })

    return {
      MODULE_NAME,
    }
  },
}
</script>
